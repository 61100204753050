import paronApi from '@/utils/paron-api'

export default {
    getAllOffers() {
        return paronApi.get('offer/all')
    },

    getOfferById(id) {
        return paronApi.get(`offer/${id}`)
    },

    createOffer(data) {
        return paronApi.post('offer/new', data)
    },

    editOfferById(id, data) {
        return paronApi.post(`offer/${id}/edit`, data)
    },

    deleteOfferById(id) {
        return paronApi.delete(`offer/${id}`)
    },

    getOffersByFilter(page, filter, sortOption, order) {
        var path = page ? `offer/filtered?page=${page}&per_page=10&` : 'offer/filtered?'
        if (sortOption) {
            path += `sortOption=${sortOption}&order=${order}`
        }
        return paronApi.post(path, filter)
    }

}
