<template>
    <v-container>
        <v-row>
            <v-col
                v-for="(filter, index) in filters.filter(element => element.searchable)"
                :key="index"
                cols="4"
            >
                <v-autocomplete
                    v-if="filter.data ? filter.type === 'api' : false"
                    v-model="localFilters[filter.value]"
                    :items="filter.data"
                    :item-text="filter.text === 'Member'? getMemberText : (filter.text === 'Partner' ? getPartnerText : (filter.text === 'Team' ? getTeamText : getCardText))"
                    return-object
                    :label="`Select ${filter.text}`"
                    small-chips
                    clearable
                />
                <v-select
                    v-else-if="filter.type === 'boolean'"
                    v-model="localFilters[filter.value]"
                    :items="filter.data"
                    :label="`Select ${filter.text}`"
                    return-object
                    small-chips
                    clearable
                />
                <v-menu
                    v-else-if="filter.type === 'date'"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="localFilters[filter.value]"
                            :label="filter.text"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            clearable
                        />
                    </template>
                    <v-date-picker
                        v-model="localFilters[filter.value]"
                        @change="setDate(filter)"
                    />
                </v-menu>
                <v-text-field
                    v-else
                    v-model="localFilters[filter.value]"
                    :type="filter.type ? filter.type : 'text'"
                    :label="filter.text"
                    clearable
                />
            </v-col>
        </v-row>
        <v-row v-if="sortOptions.length > 0">
            <v-col cols="4">
                <v-select
                    v-model="sortOption"
                    :items="sortOptions"
                    :label="`Sort by`"
                    item-value="value"
                    clearable
                />
            </v-col>
            <v-col cols="2">
                <v-select
                    v-model="order"
                    :items="['ASC', 'DESC']"
                    :prepend-icon="order === 'ASC' ? 'mdi-arrow-up-bold' : 'mdi-arrow-down-bold'"
                    return-object
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" align="end">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="mx-7"
                            :loading="loading"
                            v-bind="attrs"
                            small
                            icon
                            v-on="on"
                            @click="clearFilters"
                        >
                            <v-icon color="black">
                                mdi-reload
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Reset filters</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="mx-2"
                            :disabled="shouldDisableSubmit()"
                            :loading="loading"
                            v-bind="attrs"
                            x-large
                            icon
                            v-on="on"
                            @click="filterResults"
                        >
                            <v-icon color="success">
                                mdi-filter-outline
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Filter results</span>
                </v-tooltip>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        props: {
            filters: {
                type: Array,
                default: null
            },
            sortOptions: {
                type: Array,
                default: null
            }
        },
        data: () => {
            return {
                loading: false,
                localFilters: {},
                order: 'ASC',
                sortOption: null
            }
        },
        watch: {
            'localFilters': {
                handler(value) {
                    Object.keys(value).forEach((element) => {
                        if (value[element] === null) {
                            delete value[element]
                        }
                    })
                },
                deep: true
            }
        },
        created() {
            this.localFilters = this.$store.getters.getFilters ? this.$store.getters.getFilters : {}
            this.sortOption = this.$store.getters.getSortOption ? this.$store.getters.getSortOption : null
            this.sortOrder = this.$store.getters.getSortOrder ? this.$store.getters.getSortOrder : null
            if (this.localFilters || this.sortOption) {
                this.$emit('filterResults', this.localFilters, this.sortOption, this.order)
            }
        },
        methods: {
            clearField(field) {
                console.log('in')
                delete this.localFilters[field]
            },
            filterResults() {
                this.$store.dispatch('setFilters', this.localFilters)
                this.$store.dispatch('setSortOption', this.sortOption)
                this.$store.dispatch('setSortOrder', this.order)
                this.$emit('filterResults', this.localFilters, this.sortOption, this.order, true)
            },
            clearFilters() {
                this.filters.forEach(element => {
                    if (element.hasOwnProperty('queryValue')) {
                        element.queryValue = null
                    }
                })
                this.localFilters = {}
                this.order = 'ASC'
                this.sortOption = null
                this.$store.dispatch('setFilters', this.localFilters)
                this.$emit('reloadFilters')
            },
            shouldDisableSubmit() {
                return false
            },
            setDate(filter) {
                if (filter.queryValue !== null) {
                    this.localFilters[filter.value] = `${filter.value}:${filter.queryValue}`
                } else {
                    delete this.localFilters[filter.value]
                }
            },
            getPartnerText(partner) {
                return `${partner.title}`
            },
            getTeamText(team) {
                return `${team.title}`
            },
            getMemberText(member) {
                return `${member.firstName} ${member.lastName} - ${member.number}`
            },
            getCardText(card) {
                return `${card.number}`
            }
            // setQuery(filter) {
            //     if (filter.type === 'api') {
            //         if (filter.queryValue !== null) {
            //             this.localFilters[filter.value] = filter.queryValue.id
            //         } else {
            //             delete this.localFilters[filter.value]
            //         }
            //     } else {
            //         if (filter.queryValue !== null) {
            //             this.localFilters[filter.value] = filter.queryValue
            //         } else {
            //             delete this.localFilters[filter.value]
            //         }
            //     }
            // }
        }
    }
</script>
